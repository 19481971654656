<template>
    <div class="evaluation">
        <el-dialog :visible.sync="eval_show" title="后评估表格" custom-class="entresgister" width="70%">
            <el-steps :active="active" finish-status="success" @change="stepChange">
                <el-step v-for="item in titleData" :key="item.id" :title="item.name"></el-step>
            </el-steps>
            <el-form ref="questionForm" :model="questionForm">
                <div v-for="(item, index) in evalData" :key="item.id">
                    <div v-show="active < 4">
                        <div class="head">
                            <h3>{{ index + 1 }}.{{ item.name }}</h3>
                            <div class="oneline">
                                <div class="weight">权重系数: {{ item.weight }}</div>
                                <div>得分 <el-input type="text" v-model="item.grade" class="total_ipt" readonly></el-input>
                                </div>
                            </div>
                        </div>
                        <div class="block">
                            <div class="tips" v-if="item.isShow">{{ filterdata[0] }}</div>
                            <el-slider v-model="item.score" :format-tooltip="formatTooltip"
                                @change="silderData(item, item.id)">
                            </el-slider>
                        </div>
                    </div>

                </div>
                <div v-show="active != 4" class="total_line">
                    <div class="label_name">总分合计</div>
                    <div v-show="active == 1">
                        <el-input type="text" v-model="totalScore" class="total_ipt" readonly></el-input>
                    </div>
                    <div v-show="active == 2">
                        <el-input type="text" v-model="totalEffect" class="total_ipt" readonly></el-input>
                    </div>
                    <div v-show="active == 3">
                        <el-input type="text" v-model="totalScience" class="total_ipt" readonly></el-input>
                    </div>
                </div>
                <div v-show="active === 4">
                    <div class="standname">标准名称:{{ proName }}</div>
                    <div class="after_form">
                        <div class="name">实施状况评价</div>
                        <div class="weight">权重系数: 0.3</div>
                        <div class="number">评价分值</div>
                        <div><el-input type="text" v-model="totalScore" class="total_ipt" readonly></el-input></div>
                        <div class="number">综合分值</div>
                        <div><el-input type="text" v-model="totalScoreZ" class="total_ipt" readonly></el-input></div>
                    </div>
                    <div class="after_form">
                        <div class="name">实施效果评价</div>
                        <div class="weight">权重系数: 0.3</div>
                        <div class="number">评价分值</div>
                        <div><el-input type="text" v-model="totalEffect" class="total_ipt" readonly></el-input></div>
                        <div class="number">综合分值</div>
                        <div><el-input type="text" v-model="totalEffectZ" class="total_ipt" readonly></el-input></div>
                    </div>
                    <div class="after_form">
                        <div class="name">科学性评价</div>
                        <div class="weight">权重系数: 0.4</div>
                        <div class="number">评价分值</div>
                        <div><el-input type="text" v-model="totalScience" class="total_ipt" readonly></el-input></div>
                        <div class="number">综合分值</div>
                        <div><el-input type="text" v-model="totalScienceZ" class="total_ipt" readonly></el-input></div>
                    </div>
                    <div class="total_after">
                        <div class="label_name">总分合计</div>
                        <div>
                            <el-input type="text" v-model="finishTotal" class="total_ipt" readonly></el-input>
                        </div>
                    </div>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="eval_sure">{{ submit_text }}</el-button>
                <el-button @click="cancle">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>
 
<script>
import { qualitList, qusetion_array, qusetion_a_array, questionAnswer } from "../../../../../api/project";
export default {
    components: {},
    data() {
        return {
            evalData: [],
            page: 1,
            entCount: 0,
            limit: 10,
            name: '',
            questionForm: {
                name: '',
                issueName: '',
                weight: "",
                content: "",
                score: ""
            },
            eval_show: false,
            sort: 0,
            active: 1,
            marks: {

            },
            titleData: [],
            submit_text: '下一步',
            totalScore: 0,
            totalEffect: 0,
            totalScience: 0,
            totalScoreZ: 0,
            totalEffectZ: 0,
            totalScienceZ: 0,
            finishTotal: 0,
            proName: "",
            question_name: "",
            f_id: "",
            filterdata: [],
            proId: ""
        }
    },
    props: {
        rowData: {
            type: Object,
            default: function () {
                return {}
            }, //默认值
        }
    },
    mounted() {
        this.token = this.$store.state.token
        this.active = 1
        this.initData()
    },
    computed: {

    },
    methods: {
        formatTooltip(val) {
            return Math.ceil(val / 10);
        },
        initData() {
            let params = {
                token: this.token,
                name: this.name,
                page: this.page,
                limit: this.limit
            }
            qualitList(params).then((rec) => {
                let list = rec.data.data
                list.push({ name: '标准后评估评价表', id: 4 })
                this.titleData = list
            });
        },
        next() {
            if (this.active++ > 4) this.active = 0;
        },
        cancle() {
            this.active = 1
            this.totalScore = 0
            this.eval_show = false
            this.submit_text = '下一步'
        },
        silderData(data, id) {
            this.f_id = id;
            let score = Math.ceil(data.score / 10)
            let weight = data.weight
            data.grade = (score * weight).toFixed(1)
            // console.log(this.active, '当前索引')
            const chooseData = this.evalData.map(v => Number(v.grade));
            let total = chooseData.reduce((p, c) => p + c, 0)
            if (this.active === 1) {
                this.totalScore = total.toFixed(1)
            } else if (this.active === 2) {
                this.totalEffect = total.toFixed(1)
            } else if (this.active === 3) {
                this.totalScience = total.toFixed(1)
            }
            //控制name展示
            this.evalData.forEach(function (val) {
                val.isShow = false
            })
            for (let i in this.evalData) {
                if (id === this.evalData[i].id) {
                    this.evalData[i].isShow = true
                }
            }
            this.qusetion_item(id, score)
        },
        //表格确认提交
        eval_sure() {
            let that = this
            if (that.active < 4) {
                that.submit_text = '下一步'
                for (let i in that.evalData) {
                    if (that.evalData[i].grade === null && that.evalData[i].weight != 0) {
                        that.$message.warning("请将表单填写完整");
                        return false
                    }
                }
                if (that.active === 3) {
                    that.totalScoreZ = (that.totalScore * 0.3).toFixed(1)
                    that.totalEffectZ = (that.totalEffect * 0.3).toFixed(1)
                    that.totalScienceZ = (that.totalScience * 0.4).toFixed(1)
                    let allTotal = Number(that.totalScoreZ) + Number(that.totalEffectZ) + Number(that.totalScienceZ)
                    that.finishTotal = allTotal.toFixed(1)
                    that.submit_text = '提 交'
                }
                that.next()
                if (that.active != 4) {
                    that.qualitData()
                }
            } else if (that.active === 4) {
                //确认提交
                that.makeSure()
            }

        },
        makeSure() {
            let that = this
            that.$confirm("是否确认提交, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                that.evalData.forEach(function (val) {
                    val.score = Math.ceil(val.score / 10)
                })
                that.titleData.forEach(function (val) {
                    if (val.id === 1) {
                        val.score = that.totalScore
                    } else if (val.id === 2) {
                        val.score = that.totalEffect
                    } else if (val.id === 3) {
                        val.score = that.totalScience
                    } else {
                        val.score = that.finishTotal
                    }
                    if (val.problem != undefined) {
                        val.problem.forEach(function (it) {
                            that.evalData.forEach(function (ik) {
                                it.score = ik.score
                            })
                        })
                    }
                })

                let evaluate = {
                    s_id: that.proId,
                    type: 1,
                    id: 4,
                    evaluate: [
                        {
                            id: 1,
                            name: '实施状况评价表',
                            evScore: that.totalScore,
                            tScore: that.totalScoreZ
                        },
                        {
                            id: 2,
                            name: '实施效果评价表',
                            evScore: that.totalEffect,
                            tScore: that.totalEffectZ
                        },
                        {
                            id: 3,
                            name: '科学性评价表',
                            evScore: that.totalScience,
                            tScore: that.totalScienceZ
                        }
                    ],
                    score: that.finishTotal
                }
                let params = {
                    token: that.token,
                    list: that.titleData,
                    evaluate: evaluate
                }
                questionAnswer(params).then((rec) => {
                    // console.log(rec, '打印子项内容')
                    if (rec.code === 200) {
                        that.$message.success(rec.message);
                        that.eval_show = false
                    }
                });
            })
        },
        //标题的下一步
        stepChange(val) {
            //   console.log(val,'打印值')
            if (val === 0) {
                this.active = 1
            }
        },
        qualitData() {
            this.$nextTick(() => {
                console.log(this.rowData, 9999)
                this.proName = this.rowData.proName
                this.proId = this.rowData.id //标准id
            })
            this.eval_show = true
            let params = {
                token: this.token,
                id: this.active
            }
            qusetion_array(params).then((rec) => {
                // console.log(rec, '打印xiayibu')
                this.evalData = rec.data
                this.evalData.forEach(function (val) {
                    val.grade = null
                })
            });
        },
        qusetion_item(id, getScore) {
            // console.log(getScore, '分数')
            let that = this
            let params = {
                token: that.token,
                id: id
            }
            qusetion_a_array(params).then((rec) => {
                console.log(rec, '打印子项内容')
                let itemArr = rec.data
                itemArr.forEach(function (it) {
                    it.score = it.score.split(',')
                    it.min = Number(it.score[0])
                    it.max = Number(it.score[1])
                })
                that.filterdata = itemArr.filter(val => val.min == getScore || val.max == getScore).map(val => val.name);
                console.log(that.filterdata, '输出数组')
            });
        }


    }
}
</script>
<style scoped>
.evaluation .pageTotal {
    display: flex;
    flex-direction: row-reverse;
    margin: 38px 0;
}

.pitable .expand_title {
    display: flex;
}

.pitable .items,
.pitable .expand_title .items {
    width: 20%;
    text-align: center;
    padding-bottom: 11px;
}

.pitable .titles {
    width: 20%;
    font-weight: bold;
    text-align: center;
    padding-bottom: 11px;
}

.evaluation .block {
    padding: 11px 11px;
}

/deep/ .entresgister .el-dialog__body {
    height: 560px;
    overflow: hidden;
    overflow-y: scroll;
}

.evaluation .head {
    display: flex;
    justify-content: space-between;
}

.evaluation .head .oneline {
    display: flex;
    padding-top: 18px;
    padding-left: 20px;
    margin-right: -73px;
}

.evaluation .head .oneline .weight {
    padding-right: 12px;
    padding-top: 10px;
}

.evaluation .next_step {
    display: flex;
    justify-content: center;
    align-items: center;
}

.evaluation .head .oneline .total_ipt {
    width: 50%;
}

.evaluation .standname {
    font-size: 16px;
    text-align: left;
    color: #333;
    font-weight: 500;
    padding-top: 18px;
}

.evaluation .label_name {
    padding-top: 11px;
    padding-right: 10px;
    color: #333;
}

.evaluation .total_line {
    display: flex;
    justify-content: center;
}

.evaluation .total_after {
    display: flex;
    padding-top: 11px;
}

.evaluation .after_form {
    display: flex;
    padding: 11px 0 20px 0;
}

.evaluation .after_form div {
    width: 200px;
}

.evaluation .after_form .weight {
    margin-left: 11px;
    margin-right: 11px;
    margin-top: 12px;
    width: auto;
}

.evaluation .after_form .number {
    margin-top: 11px;
    margin-right: 12px;
    margin-left: 11px;
    width: auto;
}

.evaluation .after_form .name {
    margin-top: 12px;
    color: #333;
    font-size: 14px;
}

.evaluation .tips {
    color: #333;
    font-size: 14px;
}

/deep/ .el-input.is-readonly .el-input__inner {
    color: #333;
}
</style>
